import type {
  ApolloQueryResult,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { produce } from 'immer';

import type { AnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { GET_CANDIDATE_DETAILS } from 'src/modules/graphql/queries/candidate';
import type { GetCandidateDetails } from 'src/modules/graphql/queries/types/GetCandidateDetails';
import type { EventCaptureTestTags } from 'src/utils/eventCapture/eventCaptureUtils';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

import type { TypedAction, TypedThunkAction } from '../types';

import {
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  UPDATE_AUTHENTICATED,
  UPDATE_SESSION,
  UPDATE_TEST_HEADERS,
  UPDATE_TEST_TAGS,
  type UserAccount,
  type UserState,
} from './types';

export const initialState: UserState = {
  authenticated: undefined,
  firstName: '',
  emailAddress: '',
  userClientId: '',
  testHeaders: {},
};

export default function reducer(
  state: UserState = initialState,
  action: TypedAction,
): UserState {
  switch (action.type) {
    case UPDATE_AUTHENTICATED:
      const { authenticated } = action.payload;

      return {
        ...state,
        authenticated,
      };

    case GET_ACCOUNT_SUCCESS:
      const {
        emailAddress,
        personalDetails,
        trackingId,
        id: seekerId,
        identity,
      } = action.payload;
      const firstName = personalDetails?.firstName ?? '';
      const { actor } = identity || {};
      const { id: actorId } = actor || {};
      return {
        ...state,
        firstName,
        emailAddress,
        trackingId,
        seekerId,
        actorId,
      };

    case GET_ACCOUNT_ERROR:
      return {
        ...state,
        firstName: '',
        emailAddress: '',
        trackingId: '',
        seekerId: undefined, // eslint-disable-line no-undefined
        actorId: undefined, // eslint-disable-line no-undefined
      };

    case UPDATE_SESSION:
      const { userClientId, sessionId } = action.payload;

      return {
        ...state,
        userClientId,
        sessionId,
      };

    case UPDATE_TEST_HEADERS:
      const { testHeaders } = action.payload;

      return produce(state, (draft) => {
        draft.testHeaders = testHeaders;
      });

    case UPDATE_TEST_TAGS:
      const { testTags } = action.payload;

      return produce(state, (draft) => {
        draft.testTags = testTags;
      });

    default:
      return state;
  }
}

export const updateAuthenticated = ({
  authenticated,
}: {
  authenticated: boolean;
}): TypedAction => ({
  type: UPDATE_AUTHENTICATED,
  payload: { authenticated },
});

export const updateSession = ({
  userClientId,
  sessionId,
}: {
  userClientId: string;
  sessionId: string;
}): TypedAction => ({
  type: UPDATE_SESSION,
  payload: {
    userClientId,
    sessionId,
  },
});

export const updateTestHeaders = (testHeaders: TestHeaders): TypedAction => ({
  type: UPDATE_TEST_HEADERS,
  payload: {
    testHeaders,
  },
});

export const updateEventCaptureTestTags = (
  testTags?: EventCaptureTestTags,
): TypedAction => ({
  type: UPDATE_TEST_TAGS,
  payload: {
    testTags,
  },
});

export const getCandidateAccount =
  (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    analyticsFacade: AnalyticsFacade,
  ): TypedThunkAction =>
  (dispatch) =>
    apolloClient
      .query<GetCandidateDetails>({
        query: GET_CANDIDATE_DETAILS,
      })
      .then((response: ApolloQueryResult<GetCandidateDetails>) => {
        if (response === null) {
          throw new Error('null graphql response');
        }
        const { viewer } = response.data;
        const account: UserAccount = {
          emailAddress: viewer?.emailAddress,
          personalDetails: viewer?.personalDetails,
          trackingId: viewer?.trackingId,
          id: viewer?.id,
          identity: viewer?.identity,
        };

        const action: TypedAction = {
          type: GET_ACCOUNT_SUCCESS,
          payload: account,
          meta: {},
        };

        dispatch(action);
      })
      .catch((error: Error) => {
        const payload = { authenticated: false };
        const action: TypedAction = {
          type: GET_ACCOUNT_ERROR,
          error: true,
          payload: error,
        };
        dispatch(action);
        dispatch(updateAuthenticated(payload));
        analyticsFacade.userDetailsUpdated(payload);

        throw error;
      });
